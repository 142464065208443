import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "button"];

  copy(event) {
    const source = this.sourceTarget.dataset.sourceValue;

    if (!source) {
      return;
    }

    navigator.clipboard.writeText(source)
      .then(() => {
        if (this.hasButtonTarget) {
          const button = this.buttonTarget;
          button.classList.add("is-copied");
          setTimeout(() => {
            button.classList.remove("is-copied");
          }, 2000);
        }
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  }
}
