import { Application } from "@hotwired/stimulus"
import ConfirmController from "./controllers/confirm_controller"
import InputController from "./controllers/input_controller"
import ClipboardController from "./controllers/clipboard_controller"

const application = Application.start()

application.register("confirm", ConfirmController)
application.register("input", InputController)
application.register("clipboard", ClipboardController)

export { application }
