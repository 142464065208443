import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]

  declare readonly inputTarget: HTMLButtonElement | HTMLAnchorElement | HTMLInputElement;

  focus(event) {
      const input = this.inputTarget;
      input.focus();
  }
}
